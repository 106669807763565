<template>
  <div></div>
</template>

<script>
export default {
  name: 'SettingsNavigation',
};
</script>

<style lang="scss"></style>
